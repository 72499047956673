const SOURCE = "per4mancepartseu";

let CURRENCY = "eur";
let TAX = "5e0dbaba9e33df43f0b3a47f"; // 23%
let TAX_FLOAT = 1.23;
let LOCATION_EU = "5e0dbaba9e33df43f0b3a495"; // EU
let LOCATION_UK = "5e0dbaba9e33df43f0b3a496"; // UK
let LOCATION_NL = "65cf22ece8d07217f25510b0"; // NL

export const PAYMENT_METHODS = {
  REVOLUT: "revolut",
  PAYPAL: "607468324e5b797a767fe87d"
};

export const WIDGET_TYPE = {
  REVOLUT: "Revolut",
  PAYPAL: "PayPal"
};

export default {
  SOURCE,
  CURRENCY,
  TAX,
  TAX_FLOAT,
  LOCATION_EU,
  LOCATION_NL,
  LOCATION_UK
};
