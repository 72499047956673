import axios from "axios";

const baseURL =
  process.env.NODE_ENV === "production"
    ? "https://api.ozparts.eu"
    : "http://localhost";

const instance = axios.create({ baseURL });
const source = "per4mancepartseu";

export const enquiryFrom = ({ text, email, part }) => {
  return instance.post("/email/enquiry-form", { text, email, part, source });
};

export const quickContactForm = data => {
  return instance.post("/email/quick-contact-form", { ...data, source });
};
