<template>
  <b-container class="dealer-form-wrapper">
    <div class="form-dealer">
      <v-form ref="formDealer" class="form" v-model="valid" lazy-validation>
        <v-text-field
          v-model="form.firstname"
          :rules="rules.globalRules"
          label="First name*"
          color="secondary"
          required
        ></v-text-field>
        <v-text-field
          v-model="form.lastname"
          :rules="rules.globalRules"
          label="Last name*"
          color="secondary"
          required
        ></v-text-field>
        <v-text-field
          v-model="form.name"
          :rules="rules.globalRules"
          label="Name of the company*"
          color="secondary"
          required
        ></v-text-field>
        <v-text-field
          v-model="form.email"
          :rules="rules.emailRules"
          label="Email*"
          color="secondary"
          required
        ></v-text-field>
        <v-text-field
          v-model="form.address"
          label="Billing address"
          color="secondary"
        ></v-text-field>
        <v-text-field
          v-model="form.zip"
          label="Zip"
          color="secondary"
        ></v-text-field>
        <v-text-field
          v-model="form.city"
          label="City"
          color="secondary"
        ></v-text-field>
        <v-autocomplete
          label="Country*"
          v-model="form.country"
          :items="countries"
          item-text="value"
          item-value="key"
          dense
          required
          :rules="rules.globalRules"
          color="secondary"
        />
        <v-text-field
          v-model="form.phone"
          label="Phone"
          color="secondary"
        ></v-text-field>
        <v-textarea
          label="Delivery address (if different)"
          v-model="form.delivery_address"
          color="secondary"
        ></v-textarea>
        <v-text-field
          v-model="form.taxnumber"
          :rules="rules.globalRules"
          label="VAT number*"
          color="secondary"
          required
        ></v-text-field>
        <v-textarea
          label="Comment / Message:"
          v-model="form.text"
          color="secondary"
        ></v-textarea>
        <h3>
          By answering following voluntary questions you can help us deliver a
          tailor-made service to you:
        </h3>
        <v-select
          :items="whos"
          label="Are you a wholesaler/retailer/specialised garage or other?"
          color="secondary"
          v-model="form.who"
        />
        <v-select
          :items="customers"
          label="Are your customers individual car owners or garages and retail outlets?"
          color="secondary"
          v-model="form.customer"
        />
        <v-text-field
          v-model="form.website"
          label="Do you have an online shop (if such - what is the www)?"
          color="secondary"
        ></v-text-field>
        <v-text-field
          v-model="form.specialisation"
          label="Do you have any specialisation (vehicle makes or certain parts only)?"
          color="secondary"
        ></v-text-field>
        <v-select
          :items="ranges"
          label="Do you service local area/entire country/sell outside of country?"
          color="secondary"
          v-model="form.range"
        />
        <v-select
          :items="stocks"
          label="Do you plan to stock products in your warehouse?"
          color="secondary"
          v-model="form.stock"
        />

        <v-checkbox v-model="form.privacy" :rules="rules.globalRules" required>
          <template slot="label">
            <div class="slot-label">
              I agree with
              <router-link to="/privacy" class="link"
                >Privacy Policy*</router-link
              >
            </div>
          </template>
        </v-checkbox>

        <p>
          <small class="small">* - obligatory field</small>
        </p>

        <vue-recaptcha
          sitekey="6LfHLAcrAAAAAHAGJq1l-VlIl0gaq47xLsUwC9Q2"
          :loadRecaptchaScript="true"
          ref="recaptchaDealer"
          @verify="onCaptchaVerified"
          @expired="onCaptchaExpired"
          size="invisible"
        ></vue-recaptcha>

        <v-btn
          :disabled="!valid || loading"
          :loading="loading"
          @click="validate"
          color="primary"
          depressed
          class="btn"
          >Send Enquiry Form</v-btn
        >
      </v-form>
    </div>

    <modal-component
      v-model="modal.show"
      :width="500"
      :text="modal.text"
      :title="modal.title"
      @modal="handleModal"
    />
  </b-container>
</template>

<script>
import countries from "./../../countries.js";
import VueRecaptcha from "vue-recaptcha";
import ModalComponent from "@/components/common/Modal";
import { axiosBase } from "../../axios";
import { sendContactForm } from "../../services/formService.js";

export default {
  name: "DealerForm",
  components: { VueRecaptcha, ModalComponent },
  computed: {
    countries() {
      return Object.keys(countries).map(key => {
        return { key, value: countries[key] };
      });
    }
  },
  data: function() {
    return {
      axiosBase,
      valid: true,
      form: {
        privacy: false,
        text: "",
        stock: "",
        specialisation: "",
        specialisation_other: "",
        range: "",
        who: "",
        customer: "",
        country: "",
        name: "",
        firstname: "",
        lastname: "",
        phone: "",
        taxnumber: "",
        address: "",
        city: "",
        zip: "",
        delivery_address: "",
        website: "",
        email: ""
      },
      rules: {
        emailRules: [
          v => !!v || "This field is required",
          v => /.+@.+/.test(v) || "Invalid format"
        ],

        globalRules: [v => !!v || "This field is required"]
      },
      ranges: ["Local area", "Entire country", "Sell outside of country"],
      stocks: ["Yes", "No"],
      whos: ["Wholesaler", "Retailer", "Specialised garage", "Other"],
      customers: ["Individual car owners", "Garages and retail outlets"],
      loading: false,
      modal: {
        show: false,
        text: "",
        title: ""
      }
    };
  },
  methods: {
    handleModal() {
      this.modal.show = false;
    },
    async submitDealer() {
      const formType = "Dealer";
      try {
        const res = await sendContactForm(this.form, formType);
        if (res?.status === 200) {
          this.modal.title = "Contact Form Submitted";
          this.modal.text = "We endeavour to respond within 48 hours";
        }
      } catch (err) {
        const error = (err.response && err.response.data) || err;
        this.modal.title = "Server error";
        this.modal.text = error.message || error.errmsg || error;
      } finally {
        this.$refs.formDealer.reset();
        this.loading = false;
        this.modal.show = true;
        this.$refs.recaptchaDealer.reset();
      }
    },
    onCaptchaExpired: function() {
      this.$refs.recaptchaDealer.reset();
      this.loading = false;
    },
    onCaptchaVerified() {
      this.submitDealer();
    },
    validate() {
      if (this.$refs.formDealer.validate()) {
        this.loading = true;
        this.$refs.recaptchaDealer.execute();
      }
    },
    resetFields() {
      Object.keys(this.form).forEach(key => {
        this.form[key] = "";
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.form-dealer {
  margin: 0 auto 20px auto;
  width: 60%;

  @media screen and (max-width: 960px) {
    padding: 20px;
    width: 100%;
  }
}

h3 {
  margin: 1.5em 0;
  font-size: 1rem;
}

.small {
  margin-left: 10px;
}

.link {
  color: var(--red);
}
</style>

<style lang="scss">
.form-dealer {
  label {
    margin-bottom: 0;
  }
}
</style>
