<template>
  <b-container>
    <v-form ref="formGeneral" class="form" v-model="valid" lazy-validation>
      <v-text-field
        v-model="formGeneral.firstname"
        :rules="rules.globalRules"
        label="First name*"
        color="secondary"
        required
      ></v-text-field>
      <v-text-field
        v-model="formGeneral.lastname"
        :rules="rules.globalRules"
        label="Last name*"
        color="secondary"
        required
      ></v-text-field>
      <v-text-field
        v-model="formGeneral.email"
        :rules="rules.emailRules"
        label="E-mail*"
        color="secondary"
        required
      ></v-text-field>
      <v-text-field
        v-model="formGeneral.address"
        label="Address"
        color="secondary"
      ></v-text-field>
      <v-text-field
        v-model="formGeneral.zip"
        label="Zip"
        color="secondary"
      ></v-text-field>
      <v-text-field
        v-model="formGeneral.city"
        label="City"
        color="secondary"
      ></v-text-field>
      <v-autocomplete
        label="Country*"
        v-model="formGeneral.country"
        :items="countries"
        item-text="value"
        item-value="key"
        dense
        required
        :rules="rules.globalRules"
        color="secondary"
      />
      <v-select
        :items="types"
        label="Type of enquiry*"
        required
        color="secondary"
        :rules="rules.globalRules"
        v-model="formGeneral.type"
      ></v-select>
      <v-text-field
        v-if="withoutPart"
        v-model="formGeneral.make"
        :rules="rules.globalRules"
        label="Make*"
        color="secondary"
        required
      ></v-text-field>
      <v-text-field
        v-if="withoutPart"
        v-model="formGeneral.model"
        :rules="rules.globalRules"
        label="Model*"
        color="secondary"
        required
      ></v-text-field>
      <v-text-field
        v-if="withoutPart"
        v-model="formGeneral.year"
        type="number"
        :rules="rules.globalRules"
        label="Year*"
        color="secondary"
        required
      ></v-text-field>
      <v-text-field
        v-if="withoutPart"
        v-model="formGeneral.engine"
        :rules="rules.globalRules"
        label="Engine*"
        color="secondary"
        required
      ></v-text-field>
      <v-select
        :items="brands"
        label="Brand*"
        required
        color="secondary"
        :rules="rules.globalRules"
        v-model="formGeneral.brand"
      ></v-select>
      <v-text-field
        v-if="withPart"
        v-model="formGeneral.part"
        label="Part number*"
        required
        :rules="rules.globalRules"
        color="secondary"
      ></v-text-field>
      <v-textarea
        label="Comment / Message*:"
        :rules="rules.globalRules"
        v-model="formGeneral.text"
        color="secondary"
        required
      ></v-textarea>

      <v-checkbox
        v-model="formGeneral.privacy"
        :rules="rules.globalRules"
        required
        class="form-general-checkbox"
      >
        <template slot="label">
          <div class="slot-label">
            I agree with
            <router-link class="link" to="/privacy"
              >Privacy Policy*</router-link
            >
          </div>
        </template>
      </v-checkbox>

      <p>
        <small class="small">* - obligatory field</small>
      </p>

      <vue-recaptcha
        sitekey="6LfHLAcrAAAAAHAGJq1l-VlIl0gaq47xLsUwC9Q2"
        :loadRecaptchaScript="true"
        ref="recaptchaGeneral"
        @verify="onCaptchaVerified"
        @expired="onCaptchaExpired"
        size="invisible"
      ></vue-recaptcha>

      <v-btn
        :disabled="!valid || loading"
        :loading="loading"
        @click="validate"
        color="primary"
        depressed
        class="btn"
        >Send Enquiry Form</v-btn
      >
    </v-form>
    <modal-component
      v-model="modal.show"
      :width="500"
      :text="modal.text"
      :title="modal.title"
      @modal="handleModal"
    />
  </b-container>
</template>

<script>
import countries from "./../../countries";
import VueRecaptcha from "vue-recaptcha";
import ModalComponent from "@/components/common/Modal.vue";
import { axiosBase } from "../../axios";
import { sendContactForm } from "../../services/formService";

export default {
  name: "FormGeneral",
  components: { VueRecaptcha, ModalComponent },
  data() {
    return {
      axiosBase,
      formGeneral: {
        firstname: "",
        lastname: "",
        country: "",
        address: "",
        brand: "",
        zip: "",
        city: "",
        email: "",
        type: "",
        make: "",
        model: "",
        year: "",
        engine: "",
        part: "",
        text: "",
        privacy: ""
      },
      rules: {
        globalRules: [v => !!v || "This field is required"],
        emailRules: [
          v => !!v || "This field is required",
          v => /.+@.+/.test(v) || "Invalid format"
        ]
      },
      types: [
        "Product information requested (technical/price/availability) & I DO NOT know the part number",
        "Product information requested (technical/price/availability) & I DO know the part number",
        "Sponsorship enquiry",
        "Other questions or comments"
      ],
      brands: [
        "ACL Race Series bearing",
        "DBA brake discs",
        "DBA brake pads",
        "DBA combo",
        "Hawk",
        "Pedders",
        "Turbosmart",
        "Whiteline",
        "Xforce",
        "Xtreme Performance Clutch"
      ],
      valid: true,
      loading: false,
      modal: {
        show: false,
        text: "",
        title: ""
      }
    };
  },
  methods: {
    handleModal() {
      this.modal.show = false;
    },
    async submitGeneral() {
      const formType = "General";
      try {
        const res = await sendContactForm(this.formGeneral, formType);
        if (res?.status === 200) {
          this.modal.title = "Contact Form Submitted";
          this.modal.text = "We endeavour to respond within 48 hours";
        }
      } catch (err) {
        const error = (err.response && err.response.data) || err;
        this.modal.title = "Server error";
        this.modal.text = error.message || error.errmsg || error;
      } finally {
        this.$refs.formGeneral.reset();
        this.loading = false;
        this.modal.show = true;
        this.$refs.recaptchaGeneral.reset();
      }
    },
    onCaptchaExpired: function() {
      this.$refs.recaptchaGeneral.reset();
      this.loading = false;
    },
    onCaptchaVerified() {
      this.submitGeneral();
    },
    validate() {
      if (this.$refs.formGeneral.validate()) {
        this.loading = true;
        this.$refs.recaptchaGeneral.execute();
      }
    },
    resetFields() {
      Object.keys(this.formGeneral).forEach(key => {
        this.formGeneral[key] = "";
      });
    }
  },
  computed: {
    countries() {
      return Object.keys(countries).map(key => {
        return { key, value: countries[key] };
      });
    },
    withoutPart() {
      return (
        this.formGeneral.type ===
        "Product information requested (technical/price/availability) & I DO NOT know the part number"
      );
    },
    withPart() {
      return (
        this.formGeneral.type ===
        "Product information requested (technical/price/availability) & I DO know the part number"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  margin: 20px auto;
  width: 60%;

  @media screen and (max-width: 960px) {
    padding: 20px;
    width: 100%;
  }
}

.small {
  margin-left: 10px;
}

.link {
  color: var(--red);
}
</style>

<style lang="scss">
.form-general-checkbox {
  label {
    margin-bottom: 0;
  }
}
</style>
