<template>
  <div class="general-content">
    <ol>
      <li>
        <p class="header">Did you send my shipment out?</p>
        <p>
          If you received an invoice from our system (sent to your e-mail
          address which is set up as a "main address" in our system) it means
          that your shipment left our warehouse. Name of the carrier and
          tracking number are included on the invoice (at the top-right corner).
          So if you would like to check the progress of the shipment, please
          note the following instructions:
        </p>

        <p>
          UPS: Please access:
          <a href="http://www.ups.com/content/pl/en/index.jsx" target="_blank"
            >http://www.ups.com/content/pl/en/index.jsx</a
          >
          <br />Please enter full tracking number as on the invoice. You will
          initially see actual tracking info.
        </p>

        <p>
          EMS/Pocztex: Please access:
          <a href="http://www.pocztex.pl/dokl_inf_telef.php" target="_blank"
            >http://www.pocztex.pl/dokl_inf_telef.php</a
          >
          <br />Please enter full tracking number as on the invoice (or sent
          separately) in the “indentyfikator” field and press “szukaj”. You may
          also scroll down and click on your country to link to tracing facility
          offered by your local EMS service supplier.
        </p>
      </li>
      <li>
        <p class="header" id="return">Can I return items purchased?</p>
        <p>
          If you are not satisfied with your purchase, please follow the
          instructions below to return the item.
        </p>
        <ol>
          <li>
            <p>
              Returns are acceptable within 30 days from the day of purchase
              (date of invoice).
            </p>
          </li>
          <li>
            <p>
              All returns must be in their original conditions, originally
              packed (without any signs of opening) and include all original
              manufacturer's packaging and/or tags.
            </p>
          </li>
          <li>
            <p>
              If any parts were purchased as a set, it is not possible to return
              its individual components. In such cases, it is possible to return
              the entire set only containing all components. Returns of a single
              set's components' will be rejected and sent back at the customer's
              cost.
            </p>
          </li>

          <li>
            <p>
              Important! Please, make sure that you include in the parcel
              <span style="font-weight: bold;"
                >filled Return Form (you can download below) together with a
                copy of the invoice</span
              >
              and send it within 30 days from the day of purchase (date of
              invoice).
            </p>
            <ul>
              <li>
                <p>
                  You can download our
                  <a href="https://dba4x4.home.pl/returns" target="_blank"
                    >return form</a
                  >
                  here. Please, fill it out completely and send in the box with
                  your return. Please include your details, part number and
                  explain the reason of the return.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              Any returns not approved by us will be refused. You will be
              responsible for shipping to us and back to you.
            </p>
          </li>
          <li>
            <p>
              When shipping returned items, you are responsible to pack and ship
              items properly so the items do not get damaged or lost in transit.
            </p>
          </li>
          <li>
            <p>
              Credit notes might take a minimum of 5 working days (from the day
              of the parcel receiving) to be issued
            </p>
          </li>
          <li>
            <p>
              Please do not return any items via COD we will not accept COD
              returns
            </p>
          </li>
          <li>
            <p v-if="currentRegion === 'NL'">
              Please send returns to:
              <br />OZPARTS B.V. <br />Geloërveld, Blauwwater 15D, <br />5951 DB
              Belfeld, Netherlands
            </p>
            <p v-else>
              Please send returns to:
              <br />OZPARTS PL sp. z o.o. <br />Mazowiecka 46-48 <br />87-100
              Toruń, Poland
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p class="header" id="warranties">How can I make a warranty claim?</p>
        <p>
          If you would like to make a warranty claim on any item bought via
          Ozparts please simply choose brand of the product (from the list
          below) which you wish to make a claim for and fill up the warranty
          claim form online. Please note all fields must be filled up and
          pictures needs to be added. Once form is complete please submit it.
          Warranty claim will be automatically sent to us. Upon receiving your
          claim, we will send you a claim number and at the same time it will be
          passed to the manufacturer who will make a decision on it. If claim is
          approved credit note or replacement part FOC will be provided.
        </p>
        <p>
          <a href="https://dba4x4.home.pl/warranty/ACS" target="_blank"
            >Warranty Form - Australian Clutch Services</a
          >
        </p>
        <p>
          <a href="https://dba4x4.home.pl/warranty/DBA" target="_blank"
            >Warranty Form - Disc Brakes Australia</a
          >
        </p>
        <p>
          <a href="https://dba4x4.home.pl/warranty/HAWK" target="_blank"
            >Warranty Form - Hawk Performance</a
          >
        </p>
        <p>
          <a href="https://dba4x4.home.pl/warranty/TURBOSMART" target="_blank"
            >Warranty Form - Turbosmart</a
          >
        </p>
        <p>
          <a href="https://dba4x4.home.pl/warranty/PEDDERS" target="_blank"
            >Warranty Form - Pedders Suspension</a
          >
        </p>
        <p>
          <a href="https://dba4x4.home.pl/warranty/WHITELINE" target="_blank"
            >Warranty Form - Whiteline</a
          >
        </p>
        <p>
          <a href="https://dba4x4.home.pl/warranty/XFORCE" target="_blank"
            >Warranty Form - Xforce</a
          >
        </p>
      </li>
      <li>
        <p class="header">Why was order placed today not shipped out?</p>
        <p>
          All orders completed by payments via our system till 1:00pm are being
          dispatched on the same day. If the order was completed after 1:00pm it
          will be shipped within next working day.
        </p>
      </li>
      <li>
        <p class="header" id="payment">How can I make a payment?</p>
        <p>
          Payment for the goods can be made via Credit/Debit card supported by
          <a href="https://www.paypal.me/Ozparts" target="_blank">PayPal</a>
          platform.
        </p>
      </li>
      <li>
        <p class="header" id="shipping">Shipping Options</p>
        <Nl v-if="currentRegion === 'NL'" />
        <Eu v-else />
      </li>
    </ol>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Eu from "../shipping-options/Eng/Eu.vue";
import Nl from "../shipping-options/Eng/Nl.vue";

export default {
  name: "FAQ_EU",
  components: { Eu, Nl },
  computed: {
    ...mapState(["userRegionData"]),
    currentRegion() {
      return this.userRegionData.location.name;
    }
  }
};
</script>

<style lang="scss">
.faq-wrapper {
  .general-content {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: "Montserrat", sans-serif;
    max-width: 100vw;
  }

  ol {
    padding: 0;
    margin: 0 10px;
  }

  strong {
    font-weight: bold;
  }

  .figure {
    display: block;

    img {
      margin: 0 auto;
      max-width: 90vw;
    }

    figcaption {
      text-align: center;
      font-size: 14px;
      margin-top: 5px;
    }
  }

  .table {
    width: 100%;
    margin: 20px 0;
    max-width: 800px;
    td {
      width: 50%;
    }
  }

  p {
    font-size: 16px;
    font-weight: 300;
  }

  .header {
    font-family: "Michroma", sans-serif;
    word-spacing: 5px;
  }
}

.bold {
  font-weight: 800 !important;
  text-decoration: underline;
}
</style>
