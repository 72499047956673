export async function sendContactForm(data, formType) {
  const source = "per4mancepartseu";
  const url =
    formType === "General"
      ? `https://api.ozparts.eu/email/contact-general`
      : `https://api.ozparts.eu/email/contact-dealer`;
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...data,
        source: source,
        to: "support@ozperformance.eu"
      })
    });
    return res;
  } catch (e) {
    return e;
  }
}
