import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueLettering from "@miii/vue-lettering";
import VeeValidate from "vee-validate";
import VueGtag from "vue-gtag";
import { FontAwesomeIcon } from "./plugins/fontAwesome";
import LoadScript from "vue-plugin-load-script";
import veeValidateOptions from "@/plugins/veeValidate";

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(VeeValidate, veeValidateOptions);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueLettering);
Vue.use(LoadScript);

Vue.config.productionTip = false;

const source = "per4mancepartseu";

Vue.prototype.$source = source;

Vue.use(
  VueGtag,
  {
    config: { id: "UA-59723259-7" },
    bootstrap: false
  },
  router
);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
